import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import * as firestore from "firebase/firestore/lite";
import { graphql, navigate } from "gatsby";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

import * as eventJson from "../content/_posts/events/appparade-36.json";

const firebaseConfig = {
	apiKey: process.env.GATSBY_FIREBASE_API_KEY,
	authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
	projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
	storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.GATSBY_FIREBASE_APP_ID,
	measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID
};

const votingKey = "votingKey";

export default props => {
	const [form, setForm] = useState({
		first: "unselected",
		second: "unselected",
		third: "unselected",
		monthlyUsers: {Livesport: ""}
	});

	const [app, setApp] = useState(undefined);
	const [db, setDb] = useState(undefined);
	const [applications2, setApplications] = useState([]);
	const [event, setEvent] = useState(undefined);
	const [votingEnabled, setVotingEnabled] = useState(false);
	const [voted, setVoted] = useState(undefined);

	function getApps() {
		return eventJson.applications.map(a => {
			return {
				id: a.app_name,
				name: a.app_name
			};
		});
	}

	function getDistanceFromLatLonInM(lat1, lon1, lat2, lon2) {
		const R = 6371e3; // metres
		const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
		const φ2 = (lat2 * Math.PI) / 180;
		const Δφ = ((lat2 - lat1) * Math.PI) / 180;
		const Δλ = ((lon2 - lon1) * Math.PI) / 180;

		const a =
			Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
			Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		const d = R * c; // in metres
		return d;
	}

	function isVoteValid() {
		return (
			form.first !== "unselected" ||
			form.second !== "unselected" ||
			form.third !== "unselected" ||
			form.monthlyUsers.Livesport !== "" 
		);
	}

	useEffect(() => {
		const asyncFunction = async () => {
			const app = initializeApp(firebaseConfig);
			setApp(app);
			const db = firestore.getFirestore(app);
			setDb(db);
			await signInAnonymously(getAuth(app));
			const snapshot = await firestore.getDoc(
				firestore.doc(db, props.data.site.siteMetadata.firebaseEnvironment)
			);
			const settings = await firestore.getDoc(
				firestore.doc(db, snapshot.data().event.path)
			);
			setEvent(snapshot.data().event);

			if (!settings.data().settings.webVotingEnabled)
				return await navigate("/");

			const event = await firestore.getDoc(
				firestore.doc(db, snapshot.data().event.path)
			);

			setApplications(
				getApps().sort(
					(firstApp, secondApp) =>
						firstApp.name.toLowerCase() >= secondApp.name.toLowerCase()
				)
			);

			const voted = localStorage.getItem(`${votingKey}_${event.id}`);
			if (voted !== null && voted !== undefined) {
				setVoted(JSON.parse(voted));
				return;
			}

			try {
				const enabled =
					moment().isAfter(
						moment(event.data().voting.webVotingStart.toDate())
					) &&
					moment().isBefore(moment(event.data().voting.webVotingEnd.toDate()));
				setVotingEnabled(enabled);
			} catch (error) {
				console.error(error);
			}

			const q = firestore.query(
				firestore.collection(db, "/applications/"),
				firestore.where("event", "==", snapshot.data().event)
			);

			
		};
		asyncFunction().catch(console.error);
	}, []);

	return (
		<Layout>
			<Helmet>
				<title>AppParade | Hlasování</title>
			</Helmet>

			<hr />
			<div className="container-heading">
				<h2>// Hlasování</h2>
			</div>
			<hr />
			<div className="container apps">
				{applications2.length === 0 ? (
					<>Načítání...</>
				) : voted !== undefined ? (
					<div>
						Děkujeme za hlasování! 🎉
						<br />
						Váš hlas:
						<br />
						{voted.first !== "unselected" ? (
							<>
								1.
								{voted.firstName}
								<br />
							</>
						) : (
							""
						)}
						{voted.second !== "unselected" ? (
							<>
								2.
								{voted.secondName}
								<br />
							</>
						) : (
							""
						)}
						{voted.third !== "unselected" ? (
							<>
								3.
								{voted.thirdName}
								<br />
							</>
						) : (
							""
						)}
						{voted.monthlyUsers && voted.monthlyUsers.Livesport > 0 && (
							<>
								Podle tebe aplikaci Livesport v březnu použilo {voted.monthlyUsers.Livesport} uživatelů
								<br />
							</>
						)}
					</div>
				) : votingEnabled === false ? (
					"Hlásování se spustí až po prezentacích. 👀"
				) : (
					<div className="row">
						<div className="col">
							<form
								className="form"
								onSubmit={async e => {
									e.preventDefault();
									if (!isVoteValid()) {
										alert("Zvolte alespoň jednu aplikaci.");
										return;
									}

									try {
										form.monthlyUsers.Livesport = Number(form.monthlyUsers.Livesport)

										await firestore.setDoc(
											firestore.doc(
												db,
												"votes",
												event.id,
												"vote",
												getAuth(app).currentUser.uid
											),
											{
												...form
											}
										);

										localStorage.setItem(
											`${votingKey}_${event.id}`,
											JSON.stringify(form)
										);
										setVoted(form);
									} catch (error) {
										console.error(error);
									}
								}}
							>
								<div className="form-group row mt-2">
									<div className="col">
										<input
											pattern="(?:\+420|\+421)? ?[0-9 ]{9,12}"
											type="tel"
											className="form-control"
											placeholder="Telefonní číslo"
											required="required"
											onInvalid={event => {
												console.log(event);
												event.target.setCustomValidity(
													"Zadejte prosím platné telefonní číslo (+420 XXX XXX XXX)"
												);
											}}
											onChange={event => {
												event.target.setCustomValidity("");
												setForm({
													...form,
													phone: event.target.value.replace(/\s/g, "").trim()
												});
											}}
										/>
									</div>
								</div>
								<div className="form-group row mt-2">
									<div className="col">
										<input
											type="email"
											className="form-control"
											placeholder="Email"
											required="required"
											onChange={event => {
												setForm({ ...form, email: event.target.value });
											}}
										/>
									</div>
								</div>
								<div className="form-group row mt-2">
									<div className="col">
										<select
											name="first"
											className="form-control"
											value={form.first}
											onChange={event =>
												setForm({
													...form,
													first: event.target.value,
													firstName: applications2.find(
														a => a.id === event.target.value
													).name
												})
											}
										>
											<option disabled="disabled" value="unselected">
												1. místo
											</option>
											{applications2
												.filter(
													app => app.id !== form.third && app.id !== form.second
												)
												.map(application => (
													<option
														key={application.id}
														value={application.id}
														style={{ color: "black" }}
													>
														{application.name}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="form-group row mt-2">
									<div className="col">
										<select
											name="second"
											className="form-control"
											value={form.second}
											onChange={event =>
												setForm({
													...form,
													second: event.target.value,
													secondName: applications2.find(
														a => a.id === event.target.value
													).name
												})
											}
										>
											<option disabled="disabled" value="unselected">
												2. místo
											</option>
											{applications2
												.filter(
													app => app.id !== form.first && app.id !== form.third
												)
												.map(application => (
													<option
														key={application.id}
														value={application.id}
														style={{ color: "black" }}
													>
														{application.name}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="form-group row mt-2">
									<div className="col">
										<select
											name="third"
											className="form-control"
											value={form.third}
											onChange={event =>
												setForm({
													...form,
													third: event.target.value,
													thirdName: applications2.find(
														a => a.id === event.target.value
													).name
												})
											}
										>
											<option disabled="disabled" value="unselected">
												3. místo
											</option>
											{applications2
												.filter(
													app => app.id !== form.first && app.id !== form.second
												)
												.map(application => (
													<option
														key={application.id}
														value={application.id}
														style={{ color: "black" }}
													>
														{application.name}
													</option>
												))}
										</select>
									</div>
								</div>
								<div className="form-group row mt-2">
									<div className="col">
										<br />
										<label>
											Tipněte si kolik lidí využilo aplikaci Livesport v březnu
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="123"
											onChange={event => {
												const { value } = event.target;
												if (/^\d+$/.test(value) || value === "") {
													setForm({
														...form,
														monthlyUsers: {...form.monthlyUsers, Livesport: event.target.value}
													});
												}
											}}
											value={form.monthlyUsers.Livesport}
											inputMode="numeric"
											pattern="[0-9]*"
										/>
									</div>
								</div>
								<div className="form-group row mt-2">
									<div className="col">
										<div style={{ marginTop: "20px", marginBottom: "10px" }}>
											{" "}
											* Hlasováním souhlasíte s
{" "}
											<a href="/pravidla" style={{ color: "#CA00F4" }}>
												podmínkami soutěže
											</a>
											.
										</div>
										<button
											type="submit"
											className="btn btn-default btn-lg"
											style={{ color: "#fff" }}
										>
											Hlasovat
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				)}
			</div>
		</Layout>
	);
};
export const query = graphql`
	{
		site {
			siteMetadata {
				firebaseEnvironment
			}
		}
	}
`;
